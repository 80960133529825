import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import OtpInput from "react-otp-input";
import "../signup.scss";
import { Button } from "../../../common/Button";
import { colorPalette } from "../../../../theme/color-palette";
import Countdown from "react-countdown";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import {
  successHandler,
  toastHandler,
} from "../../../../Redux/userReducer/user.action";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as FbBlueSvg } from "../../../../assets/svgs/fbBlueSvg.svg";
import { facebookLogin, googleLogin } from "../../../../Api/Auth";

export const LoginOtp = ({
  loginForm,
  setLoginForm,
  otp,
  setOtp,
  loginHandler,
  verifyOtpHandler,
  timer,
  setTimer,
  showLoginOtp,
  setShowLoginOtp,
  sendOtpTime,
  resendOtpHandler,
  enableResendotp,
  type,
  handleClose,
}) => {
  const { white500, primary500 } = colorPalette;
  const [phoneNumber, setPhoneNumber] = useState({
    countryCode: "91",
    mobileNumber: "",
  });
  const [isValidPhone, setIsValidPhone] = useState(false);

  const dispatch = useDispatch();
  const {
    user: { openToast },
  } = useSelector((state) => state);

  useEffect(() => {
    if (loginForm?.countryCode === "91") {
      loginForm?.mobileNumber?.length >= 10
        ? setIsValidPhone(true)
        : setIsValidPhone(false);
    } else {
      setIsValidPhone(true);
    }
  }, [loginForm, phoneNumber]);

  useEffect(() => {
    setLoginForm({
      countryCode: phoneNumber?.countryCode,
      mobileNumber: phoneNumber?.mobileNumber?.slice(
        phoneNumber?.countryCode?.length
      ),
    });
  }, [phoneNumber]);

  const responseFacebook = (response) => {
    if (response?.accessToken) {
      facebookLogin({
        token: response?.accessToken,
        firstName: response?.first_name,
        lastName: response?.last_name,
        email: response?.email,
      })
        .then(({ data }) => {
          localStorage.setItem("user", JSON.stringify(data));
          dispatch(successHandler());
          handleClose(setShowLoginOtp);
        })
        .catch((err) => {
          handleClose(setShowLoginOtp);
          dispatch(
            toastHandler({
              ...openToast,
              open: true,
              message: err?.response?.data?.message,
              severity: "error",
            })
          );
        });
    }
  };

  const responseGoogle = (response) => {
    if (response?.accessToken) {
      googleLogin({ token: response?.tokenObj?.id_token })
        .then(({ data }) => {
          localStorage.setItem("user", JSON.stringify(data));
          dispatch(successHandler());
          handleClose(setShowLoginOtp);
        })
        .catch((err) => {
          handleClose(setShowLoginOtp);
          dispatch(
            toastHandler({
              ...openToast,
              open: true,
              message: err?.response?.data?.message,
              severity: "error",
            })
          );
        });
    }
  };

  return (
    <>
      <div>
        <section>
          <div className="w-full  py-4">
            <Box
              component="form"
              sx={{
                "& > :not(style)": {},
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <p className="text-xs font-medium mb-2">Mobile Number</p>
                <PhoneInput
                  country={"in"}
                  value={phoneNumber?.countryCode}
                  inputProps={{
                    required: true,
                    autoFocus: true,
                  }}
                  countryCodeEditable={false}
                  onChange={(value, data, event, formattedValue) => {
                    type === "desktop" && setShowLoginOtp(false);
                    setPhoneNumber({
                      countryCode: data?.dialCode,
                      mobileNumber: value,
                    });
                  }}
                />
              </div>
              {!showLoginOtp && (
                <div className="mt-5">
                  <Button
                    label="SEND OTP"
                    color={white500}
                    bgColor={primary500}
                    size={"medium"}
                    variant={"contained"}
                    disabled={!isValidPhone}
                    width={"8rem"}
                    fontWeight={700}
                    onClickHandler={(e) => loginHandler(e)}
                  />
                </div>
              )}
            </Box>
          </div>
        </section>
        {showLoginOtp && (
          <section>
            <div className="w-full ">
              <p className="mb-2">OTP Code</p>
              <OtpInput
                value={otp}
                className="otp-input"
                onChange={(otp) => setOtp(otp)}
                numInputs={6}
                separator={<span className="ml-2"></span>}
              />
              <Countdown date={sendOtpTime + timer} renderer={renderer} />
              <div className="mt-4">
                <Button
                  label="CONFIRM OTP"
                  color={white500}
                  bgColor={primary500}
                  size={"medium"}
                  variant={"contained"}
                  disabled={otp.toString().length < 6}
                  width={"9rem"}
                  fontWeight={700}
                  onClickHandler={(e) => verifyOtpHandler(e)}
                />
              </div>
              <p className="mt-6">
                Didn't receive OTP?{" "}
                <span
                  onClick={(e) => {
                    setTimer(timer + 1);
                    resendOtpHandler("login");
                  }}
                  className={`${
                    enableResendotp
                      ? "text-link500 cursor-pointer"
                      : "text-gray600"
                  }`}
                >
                  Resend OTP
                </span>
              </p>
            </div>
          </section>
        )}
      </div>

      {type === "desktop" && (
        <>
          <div className="mt-2 w-full google-btn">
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_ID}
              buttonText="Login with Google"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
              scope="profile"
            />
          </div>

          <div className="mt-4 w-full google-btn">
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_ID}
              fields="name,first_name, last_name, email,picture"
              scope="public_profile"
              callback={responseFacebook}
              cssClass="my-facebook-button-class"
              icon={<FbBlueSvg />}
            />
          </div>
        </>
      )}
    </>
  );
};

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return "";
  } else {
    // Render a countdown
    return (
      <p className="mt-4">
        You can resend OTP in {minutes}:{seconds} minutes
      </p>
    );
  }
};
